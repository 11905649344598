import { DefaultTheme } from 'styled-components';

const theme = {
  basicWidth: '320px',

  color: {
    primary: '#1569FF',
    secondary: '#FE3D59',
    third: '#C7E9FF',
    black: '#222222',
    darkGrey: '#676767',
    grey: '#afafaf',
    lightGrey: '#cecece',
    veryLightGrey: '#efefef',
    orange: '#EF9A45',
    green: '#46b775',
  },
};

export default theme;
