import React from 'react';
import * as s from './FooterStyled';
import { Link } from 'react-router-dom';

interface Props {
  comInfo: any;
}

const FooterPresenter = (props: Props) => {
  return (
    <s.Container>
      <s.LogoArea>
        <s.LogoImg src={props.comInfo?.logo} />
      </s.LogoArea>
      <s.TextArea>
        <s.BottomTextBright>
          {`상호명: ${props.comInfo.comNm} · 대표이사:${props.comInfo.representitive} · 개인정보책임관리자:${props.comInfo.representitive}
          · 사업자등록번호: ${props.comInfo.regno}`}
        </s.BottomTextBright>
        <s.BottomTextBright>{`${props.comInfo.address}`}</s.BottomTextBright>
        <s.BottomTextBright>
          {`고객센터:${props.comInfo.callCenter} · 이메일:${props.comInfo.helpEmail}`}
        </s.BottomTextBright>
        <s.BottomTextDark>
          Copyright ©DoubleT Corp. All Rights Reserved.
        </s.BottomTextDark>
      </s.TextArea>
    </s.Container>
  );
};

export default FooterPresenter;
