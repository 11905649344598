import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import recoilItem from 'util/recoilitem';

const CountdownClock = () => {
  const [timeSeconds, setTimeSeconds] = useState<number>(
    moment(moment().format('YYYY-MM-DD 23:59:59')).diff(
      moment().format('YYYY-MM-DD HH:mm:ss'),
      'second',
    ),
  );

  useEffect(() => {
    if (timeSeconds > 0) {
      const timer = setInterval(() => {
        setTimeSeconds((prevSeconds: number) => prevSeconds - 1);
      }, 1000);

      return () => clearInterval(timer); // Clean up the interval on unmount
    }
  }, [timeSeconds]);

  const getMinuteString = () => {
    let string = '';
    string += String(Math.floor(timeSeconds / 60 / 60)).padStart(2, '0');
    string += ':';
    string += String(Math.floor(Number(timeSeconds / 60)) % 60).padStart(
      2,
      '0',
    );
    string += ':';
    string += String(timeSeconds % 60).padStart(2, '0');

    return string;
  };

  return (
    <>
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '1rem',
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: '1rem',
          }}
        >
          <div
            style={{
              padding: '1rem',
              backgroundColor: 'black',
              color: 'white',
              fontSize: '2rem',
              fontWeight: 'bold',
              borderRadius: '0.5rem',
            }}
          >
            {getMinuteString().substring(0, 1)}
          </div>
          <div
            style={{
              padding: '1rem',
              backgroundColor: 'black',
              color: 'white',
              fontSize: '2rem',
              fontWeight: 'bold',
              borderRadius: '0.5rem',
            }}
          >
            {getMinuteString().substring(1, 2)}
          </div>
          :
          <div
            style={{
              padding: '1rem',
              backgroundColor: 'black',
              color: 'white',
              fontSize: '2rem',
              fontWeight: 'bold',
              borderRadius: '0.5rem',
            }}
          >
            {getMinuteString().substring(3, 4)}
          </div>
          <div
            style={{
              padding: '1rem',
              backgroundColor: 'black',
              color: 'white',
              fontSize: '2rem',
              fontWeight: 'bold',
              borderRadius: '0.5rem',
            }}
          >
            {getMinuteString().substring(4, 5)}
          </div>
          :
          <div
            style={{
              padding: '1rem',
              backgroundColor: 'black',
              color: 'white',
              fontSize: '2rem',
              fontWeight: 'bold',
              borderRadius: '0.5rem',
            }}
          >
            {getMinuteString().substring(6, 7)}
          </div>
          <div
            style={{
              padding: '1rem',
              backgroundColor: 'black',
              color: 'white',
              fontSize: '2rem',
              fontWeight: 'bold',
              borderRadius: '0.5rem',
            }}
          >
            {getMinuteString().substring(7, 8)}
          </div>
        </div>
      </div>
    </>
  );
};

CountdownClock.defaultProps = {};

export default CountdownClock;
