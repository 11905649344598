import React, { Dispatch, SetStateAction } from 'react';
import * as s from './LoginStyled';
import { Link } from 'react-router-dom';

interface LoginProps {
  comInfo: any;
  loginId: string;
  setLoginId: Dispatch<SetStateAction<string>>;
  password: string;
  setPassword: Dispatch<SetStateAction<string>>;
  login: () => void;
  checkEnter: (e: any) => void;
}

const LoginPresenter = (props: LoginProps) => {
  return (
    <s.Container>
      <s.BackgroundImage src={props.comInfo.background} />
      <s.BackgroundImageWrapper>
        <s.LogoArea src={props.comInfo.logo} />
        <s.LoginTitle>Welcome</s.LoginTitle>
        <s.LoginSubtitle>삶에 가치를 더하는 맞춤형 복지 서비스</s.LoginSubtitle>
        <s.LoginInputWrapper>
          <s.LoginInputLabel>ID</s.LoginInputLabel>
          <s.LoginInput
            type="text"
            value={props.loginId}
            onChange={(e) => props.setLoginId(e.target.value)}
            onKeyDown={(e) => props.checkEnter(e)}
          ></s.LoginInput>
        </s.LoginInputWrapper>
        <s.LoginInputWrapper>
          <s.LoginInputLabel>PW</s.LoginInputLabel>
          <s.LoginInput
            type="password"
            value={props.password}
            onChange={(e) => props.setPassword(e.target.value)}
            onKeyDown={(e) => props.checkEnter(e)}
          ></s.LoginInput>
        </s.LoginInputWrapper>
        <s.LoginButton onClick={() => props.login()}>로그인</s.LoginButton>
        {/* <s.SignUpButton to="/signUp">임직원등록 회원가입</s.SignUpButton> */}
        {/* <s.SignUpButton to="https://nicepick.co.kr/signin" target="_blank">
          임직원등록 회원가입
        </s.SignUpButton> */}
      </s.BackgroundImageWrapper>
    </s.Container>
  );
};

export default LoginPresenter;
