import React, { useState, useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { userApi } from 'api/api-user';
import recoilItem from 'util/recoilitem';
import { makeMsg } from 'util/util';

import LoginPresenter from './LoginPresenter';

const LoginContainer = () => {
  const comInfo = useRecoilValue(recoilItem.comInfo);
  const [isLoading, setIsLoading] = useRecoilState(recoilItem.isLoading);
  const [token, setToken] = useRecoilState(recoilItem.token);
  const [userInfo, setUserInfo] = useRecoilState(recoilItem.userInfo);

  const [loginId, setLoginId] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  useEffect(() => {
    setIsLoading(false);
  }, []);

  const login = async () => {
    try {
      if (!loginId.replaceAll(' ', '')) {
        makeMsg('아이디를 입력하세요.', 'warning');
        return;
      }

      if (!password.replaceAll(' ', '')) {
        makeMsg('비밀번호를 입력하세요', 'warning');
        return;
      }

      const data = {
        loginId: loginId,
        password: password,
      };

      setIsLoading(true);

      const loginRes = await userApi.login(data);
      if (loginRes.data.rsltCd === '00') {
        setToken({ accessToken: loginRes.data.data.token });
        setUserInfo({
          userId: loginRes.data.data.userInfo.userId,
          userNm: loginRes.data.data.userInfo.userNm,
          userMobno: loginRes.data.data.userInfo.userMobno,
          userEmail: loginRes.data.data.userInfo.userEmail,
          birthDt: loginRes.data.data.userInfo.birthDt,
          userTypeCd: loginRes.data.data.userInfo.userTypeCd,
          userStCd: loginRes.data.data.userInfo.userStCd,
          comId: loginRes.data.data.userInfo.comId,
        });
        makeMsg(
          `${loginRes.data.data.userInfo.userNm}님 반갑습니다.`,
          'success',
        );
      } else if (loginRes.data.rsltCd === '05') {
        makeMsg('일치하는 사용자가 없습니다.', 'error');
      } else if (loginRes.data.rsltCd === '06') {
        makeMsg('탈퇴된 계정입니다.', 'error');
      } else if (loginRes.data.rsltCd === '07') {
        makeMsg('비밀번호를 확인해주세요.', 'error');
      } else if (loginRes.data.rsltCd === '09') {
        makeMsg('가입승인 대기중입니다. 메일을 확인해주세요.', 'warning');
      } else {
        makeMsg('네트워크 오류가 발생하였습니다.', 'error');
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.error(e);
      makeMsg('네트워크 오류가 발생하였습니다.', 'error');
    }
  };

  const checkEnter = (event: any) => {
    if (event.key === 'Enter') {
      login();
    }
  };

  return (
    <>
      <LoginPresenter
        comInfo={comInfo}
        loginId={loginId}
        setLoginId={setLoginId}
        password={password}
        setPassword={setPassword}
        login={login}
        checkEnter={checkEnter}
      />
    </>
  );
};

LoginContainer.defaultProps = {};

export default LoginContainer;
