import { RecoilRoot } from 'recoil';

import Router from './views/Router';

declare const window: Window &
  typeof globalThis & {
    IMP: any;
  };

function App() {
  const IMP = window.IMP; // 생략 가능
  // 개발 : imp10145605
  // 운영 : imp46515117
  IMP.init('imp46515117'); // 예: imp00000000a
  return (
    <RecoilRoot>
      <Router />
    </RecoilRoot>
  );
}

export default App;
